import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence, // OR: inMemoryPersistence, browserSessionPersistence; see https://firebase.google.com/docs/auth/web/auth-state-persistence
  connectAuthEmulator,
  signInWithEmailLink,
  linkWithCredential,
  updatePassword,
} from "firebase/auth";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  update,
  query,
  orderByChild,
  limitToFirst,
  startAt,
  push,
  connectDatabaseEmulator,
} from "firebase/database";
import {
  SortByGrantSize,
  SortByGrantSizeFilter,
} from "./Components/SortBy/SortByGrantSize.ts";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { GA4React } from "ga-4-react";
import {
  setUserState,
  setSignUpModalState,
  setLoginModalState,
  setUsersOffersURL,
} from "./App/Features/UserSplice";
import Store from "./App/Store";
import {
  doc,
  getDoc,
  setDoc,
  getFirestore,
  query as fQuery,
  orderBy,
  limit,
  collection,
  getDocs,
  where,
  updateDoc,
  startAfter as fStartAfter,
  addDoc,
  connectFirestoreEmulator,
} from "firebase/firestore";
import {
  getStorage,
  uploadBytes,
  ref as sRef,
  getDownloadURL,
  connectStorageEmulator,
} from "firebase/storage";
import Papa from "papaparse";
import currency from "currency.js";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import {
  dateOfBirthFormatter,
  creditScoreFormatter,
  propertyStatusFormatter,
  payFrequencyFormatter,
  employmentStatusFormatter,
  highestLevelOfEducationFormatter,
  payFrequencyLegacySupportFormatter,
  evenHighestLevelOfEducationFormatter,
  evenEmploymentStatusFormatter,
  evenPayFrequencyFormatter,
  evenCreditScoreFormatter,
  evenPropertyStatusFormatter,
} from "./utils/formatters";
import { completedAllRequiredSunfishFormFields } from "./utils/sunfishFormChecker";

export const minPasswordStrength = 3;

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBooRSb22X80lmYJxHWpu42LtpXdvpgqx4",
  authDomain: "stage-sunfish.firebaseapp.com",
  databaseURL: "https://stage-sunfish-default-rtdb.firebaseio.com",
  projectId: "stage-sunfish",
  storageBucket: "stage-sunfish.appspot.com",
  messagingSenderId: "444378594597",
  appId: "1:444378594597:web:cf792001a327117a95eecc",
  measurementId: "G-VC9SXB45CH",
  evenIntegration:
    "https://embed.hifiona.com/ui/multi-product/index.html?partner=sunfishpl&access_token=28246b9c-b66e-46cf-93ca-3434616a818b_68e6c4f4-5af6-4130-9547-fcce8565458f&company_uuid=4b475ae0-bb53-4bea-9ffe-5e8ece7f70b2&productType=loan&pw_fiona=true&available_products=loan",
};

export const wordPressUrl = "https://joinsunfish.staging.mysites.io";
export const authContinueUrl = "https://member-service-4eocndklrq-ue.a.run.app";

const costCalculatorUrl =
  "https://us-central1-stage-sunfish.cloudfunctions.net/calculateCost";

const requestEmailSupportUrl =
  "https://us-central1-stage-sunfish.cloudfunctions.net/sendContactMeEmail";

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const realtimeDb = getDatabase(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
const ga4react = new GA4React(firebaseConfig["measurementId"]).initialize();

const pageSize = 6;

if (
  (window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1") &&
  window.location.port === "5003"
) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectDatabaseEmulator(realtimeDb, "localhost", 9000);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export async function createAccountPassword(password) {
  auth.onAuthStateChanged((newUser) => {
    if (newUser) {
      updatePassword(newUser, password);
    }
  });
}

export async function resendHipaaJourneyInvite(data) {
  return new Promise((resolve, reject) => {
    const resendHipaaJourneyInvite = httpsCallable(
      functions,
      "resendHipaaJourneyInvite",
    );
    resendHipaaJourneyInvite(data).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function getJourneyHipaaConsent(data) {
  return new Promise((resolve, reject) => {
    const getJourneyHipaaConsent = httpsCallable(
      functions,
      "getJourneyHipaaConsent",
    );
    getJourneyHipaaConsent(data).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function sendHipaaJourneyInvite(data) {
  return new Promise((resolve, reject) => {
    const sendHipaaJourneyInvite = httpsCallable(
      functions,
      "sendHipaaJourneyInvite",
    );
    sendHipaaJourneyInvite(data).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function signInWithLink(email, href) {
  await signInWithEmailLink(auth, email, href);
}

export async function completeJourney(data) {
  return new Promise((resolve, reject) => {
    const completeJourney = httpsCallable(functions, "completeJourney");
    completeJourney(data).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function sendHipaaJourneyEmail(data) {
  return new Promise((resolve, reject) => {
    const sendHipaaJourneyEmail = httpsCallable(
      functions,
      "sendHipaaJourneyEmail",
    );
    sendHipaaJourneyEmail(data)
      .then((res) => {
        const data = res.data;
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        console.error(error);
        reject();
      });
  });
}

export async function getWarrantyHipaaConsentUrl() {
  const storageRef = sRef(
    storage,
    "gs://stage-sunfish.appspot.com/hipaa_consent/base/hipaa_form_warranty_program_v1.pdf",
  );
  const url = await getDownloadURL(storageRef);
  return url;
}

export async function getUserWarrantyHipaaConsentUrl(userId) {
  const storageRef = sRef(storage, `/hipaa_consent/${userId}`);
  const url = await getDownloadURL(storageRef);
  return url;
}

export async function getHipaaConsent(userId) {
  const reqPath = `/hipaa_consent/${userId}`;
  const dbRef = ref(realtimeDb, reqPath);
  const qSnapshot = await get(dbRef);
  if (qSnapshot.exists()) {
    return qSnapshot.val();
  }
  return null;
}

export async function postHipaaData(userId, data, blob) {
  const reqPath = `/hipaa_consent/${userId}`;
  const dbRef = ref(realtimeDb, reqPath);
  await set(dbRef, data);
  const storageRef = sRef(storage, `/hipaa_consent/${userId}`);
  uploadBytes(storageRef, blob);
  var fileURL = URL.createObjectURL(blob, {
    type: "application/pdf",
  });
  return fileURL;
}

export async function partnerUsersAtFormCompletion() {
  return new Promise((resolve, reject) => {
    const partnerUsersAtFormCompletion = httpsCallable(
      functions,
      "partnerUsersAtFormCompletion",
    );
    partnerUsersAtFormCompletion().then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function progressPartnerUser(requestData) {
  return new Promise((resolve, reject) => {
    const progressPartnerUser = httpsCallable(functions, "progressPartnerUser");
    progressPartnerUser(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function getPartnerReport(requestData) {
  return new Promise((resolve, reject) => {
    const getPartnerReport = httpsCallable(functions, "getPartnerReport");
    getPartnerReport(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function postPartnerUser(partner) {
  return new Promise((resolve, reject) => {
    const postPartnerUser = httpsCallable(functions, "postPartnerUser");
    postPartnerUser({
      partner: partner,
    }).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data.affiliateUserId);
      } else {
        reject();
      }
    });
  });
}

export async function postWarrantyLead(email) {
  return new Promise((resolve, reject) => {
    const setupWarrantyLead = httpsCallable(functions, "setupWarrantyLead");
    setupWarrantyLead({
      email,
    }).then((res) => {
      const data = res.data;
      if (data === 204) {
        resolve();
      } else {
        reject(data);
      }
    });
  });
}

export async function postPartnerCostCalculatorEngagement(
  userId,
  email,
  partnerName,
  type,
) {
  return new Promise((resolve, reject) => {
    const postPartnerCostCalculatorEngagement = httpsCallable(
      functions,
      "postPartnerCostCalculatorEngagement",
    );
    postPartnerCostCalculatorEngagement({
      userId,
      email,
      partnerName,
      type,
    }).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data.affiliateUserId);
      } else {
        reject();
      }
    });
  });
}
export async function postPartnerGrantsEngagement(userId, email, partnerName) {
  return new Promise((resolve, reject) => {
    const postPartnerGrantsEngagement = httpsCallable(
      functions,
      "postPartnerGrantsEngagement",
    );
    postPartnerGrantsEngagement({
      userId,
      email,
      partnerName,
    }).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data.affiliateUserId);
      } else {
        reject();
      }
    });
  });
}
export async function postPartnerConsultationCallEngagement(
  userId,
  email,
  partnerName,
) {
  return new Promise((resolve, reject) => {
    const postPartnerConsultationCallEngagement = httpsCallable(
      functions,
      "postPartnerConsultationCallEngagement",
    );
    postPartnerConsultationCallEngagement({
      userId,
      email,
      partnerName,
    }).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data.affiliateUserId);
      } else {
        reject();
      }
    });
  });
}

export async function getTrackableLinks() {
  return new Promise((resolve, reject) => {
    const getTrackableLinks = httpsCallable(functions, "getTrackableLinks");
    getTrackableLinks().then((res) => {
      const data = res.data;
      if (data) {
        const list = Object.values(data).sort((a, b) =>
          a.linkName.localeCompare(b.linkName),
        );
        const result = list.reduce(function (map, obj) {
          map[obj.id] = { ...obj };
          return map;
        }, {});
        resolve(result);
      } else {
        reject();
      }
    });
  });
}

export async function getAffiliates() {
  return new Promise((resolve, reject) => {
    const getAffiliates = httpsCallable(functions, "getAffiliates");
    getAffiliates().then((res) => {
      const data = res.data;
      if (data) {
        const list = Object.values(data).sort((a, b) =>
          a.partnerName.localeCompare(b.partnerName),
        );
        const result = list.reduce(function (map, obj) {
          map[obj.id] = { ...obj };
          return map;
        }, {});
        resolve(result);
      } else {
        reject();
      }
    });
  });
}

export async function postAffiliatedUser(affiliateId) {
  return new Promise((resolve, reject) => {
    const postAffiliatedUser = httpsCallable(functions, "postAffiliatedUser");
    postAffiliatedUser({
      affiliateId: affiliateId,
    }).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data.affiliateUserId);
      } else {
        reject();
      }
    });
  });
}

export async function updateAffiliatedUserAffiliation(requestData) {
  return new Promise((resolve, reject) => {
    const updateAffiliatedUserAffiliation = httpsCallable(
      functions,
      "updateAffiliatedUserAffiliation",
    );
    updateAffiliatedUserAffiliation(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data.affiliateUserId);
      } else {
        reject();
      }
    });
  });
}

export async function progressAffiliatedUser(requestData) {
  return new Promise((resolve, reject) => {
    const postAffiliatedUser = httpsCallable(
      functions,
      "progressAffiliatedUser",
    );
    postAffiliatedUser(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function updateAffiliate(requestData) {
  return new Promise((resolve, reject) => {
    const updateAffiliate = httpsCallable(functions, "updateAffiliate");
    updateAffiliate(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function progressTrackableLink(requestData) {
  return new Promise((resolve, reject) => {
    const progressTrackableLink = httpsCallable(
      functions,
      "progressTrackableLink",
    );
    progressTrackableLink(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function updateTrackableLink(requestData) {
  return new Promise((resolve, reject) => {
    const updateTrackableLink = httpsCallable(functions, "updateTrackableLink");
    updateTrackableLink(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function postTrackableLinks(requestData) {
  return new Promise((resolve, reject) => {
    const postTrackableLinks = httpsCallable(functions, "postTrackableLinks");
    postTrackableLinks(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function postTrackableLinkUser(requestData) {
  return new Promise((resolve, reject) => {
    const postTrackableLinkUser = httpsCallable(
      functions,
      "postTrackableLinkUser",
    );
    postTrackableLinkUser(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function postAffiliatePartner(requestData) {
  return new Promise((resolve, reject) => {
    const postAffiliatePartner = httpsCallable(
      functions,
      "postAffiliatePartner",
    );
    postAffiliatePartner(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function usersAtFormCompletion() {
  return new Promise((resolve, reject) => {
    const usersAtFormCompletion = httpsCallable(
      functions,
      "usersAtFormCompletion",
    );
    usersAtFormCompletion().then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function getAffiliatePartnerReport(requestData) {
  return new Promise((resolve, reject) => {
    const getAffiliateReport = httpsCallable(functions, "getAffiliateReport");
    getAffiliateReport(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function getTrackableLinkReport(requestData) {
  return new Promise((resolve, reject) => {
    const getTrackableLinkReport = httpsCallable(
      functions,
      "getTrackableLinkReport",
    );
    getTrackableLinkReport(requestData).then((res) => {
      const data = res.data;
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
}

export async function postPartnerLeads(partnerLead) {
  const reqPath = `/partner_leads/`;
  const dbRef = ref(realtimeDb, reqPath);
  await push(dbRef, partnerLead);
}

export async function getPartnersMetadata() {
  const q = fQuery(collection(firestore, "/partners/"));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const data = querySnapshot.docs.map((e) => ({ ...e.data(), id: e.id }));
    const list = Object.values(data).sort((a, b) => {
      const aName = a?.name;
      const bName = b?.name;

      if (!aName && !bName) {
        return 0; // equal
      } else if (!aName && bName) {
        return -1; // a < b
      } else if (aName && !bName) {
        return 1; // a > b
      } else {
        return aName.localeCompare(bName); // proper comparison
      }
    });
    return list;
  }
  return null;
}

export async function setPartnerMetadata(data) {
  await setDoc(doc(firestore, "partners", data.id), {
    name: data.name,
    message: data.message,
    route: data.route,
    type: data.type,
    active: data.active,
  });
}

export async function addPartnerMetadata(data) {
  const docRef = await addDoc(collection(firestore, "partners"), data);
  if (docRef?.id) {
    return true;
  } else {
    return false;
  }
}

export async function getPartnerMetadata(route) {
  const q = fQuery(
    collection(firestore, "/partners/"),
    where("route", "==", `/${route}`),
    where("active", "==", true),
    limit(1),
  );
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return querySnapshot?.docs?.pop()?.data();
  }
  return null;
}

export function isLoggedIn() {
  return !!auth.currentUser;
}

export function getCurrentUser() {
  if (!auth.currentUser) {
    return null;
  }
  return auth.currentUser;
}

export async function updateEvent(data) {
  const eventRef = doc(firestore, "events", data.id);
  await updateDoc(
    eventRef,
    data?.footerText !== undefined
      ? {
          title: data?.title,
          titleLinkUrl: data?.titleLinkUrl,
          titleLinkUrlAuthRequired: data?.titleLinkUrlAuthRequired ?? false,
          description: data?.description,
          date: data?.date,
          footerText: data?.footerText,
          footerLinkText: data?.footerLinkText,
          footerLinkUrl: data?.footerLinkUrl,
        }
      : {
          title: data?.title,
          titleLinkUrl: data?.titleLinkUrl,
          titleLinkUrlAuthRequired: data?.titleLinkUrlAuthRequired ?? false,
          description: data?.description,
          date: data?.date,
        },
  );
}

export async function getEvents(lastElement) {
  const reqPath = "events";
  if (lastElement) {
    return await getPaginatedPosts(reqPath, lastElement);
  } else {
    return await getPosts(reqPath, lastElement);
  }
}

export async function getBlogs(lastElement) {
  const reqPath = "blogs";
  if (lastElement) {
    return await getPaginatedPosts(reqPath, lastElement);
  } else {
    return await getPosts(reqPath, lastElement);
  }
}

export async function getPaginatedPosts(filePath, lastElement) {
  const q = fQuery(
    collection(firestore, filePath),
    where("date", "!=", null),
    where("hide", "==", false),
    orderBy("date", "desc"),
    fStartAfter(lastElement?.doc),
    limit(pageSize),
  );
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return querySnapshot.docs.map((e) => ({ id: e.id, ...e.data(), doc: e }));
  }
  return null;
}

export async function getPosts(filePath) {
  const q = fQuery(
    collection(firestore, filePath),
    where("date", "!=", null),
    where("hide", "==", false),
    orderBy("date", "desc"),
    limit(pageSize),
  );
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return querySnapshot.docs.map((e) => ({ id: e.id, ...e.data(), doc: e }));
  }
  return null;
}

export async function setEventsMetadata(events) {
  const filePath = `/events/${new Date().toUTCString()}/`;
  await setPostMetaData(filePath, events);
}

export async function setBlogMetadata(blog) {
  const filePath = `/blogs/${new Date().toUTCString()}/`;
  await setPostMetaData(filePath, blog);
}

async function setPostMetaData(filePath, data) {
  const docRef = doc(firestore, filePath);
  await setDoc(docRef, data);
}

export async function uploadBlogImage(id, data) {
  const filePath = `blogs/${id}/${new Date().toUTCString()}.png`;
  return await uploadAdminImage(filePath, data);
}

export async function uploadEventImage(id, data) {
  const filePath = `events/${id}/${new Date().toUTCString()}.png`;
  return await uploadAdminImage(filePath, data);
}

async function uploadAdminImage(filePath, data) {
  const storageRef = sRef(storage, filePath);
  const uploadSnap = await uploadBytes(storageRef, data);
  if (uploadSnap?.metadata) {
    const downloadURL = await getDownloadURL(uploadSnap.ref);
    return downloadURL;
  }
  return null;
}

export async function getUserAcls(id) {
  try {
    const docRef = doc(firestore, `administrators/${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (e) {
    return null;
  }
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export async function createUsersOffersUrl(userUid, formValues) {
  const results = await areFeatureFlagsEnabled([
    "evenIntegration",
    "frbPromo",
    "reducedFormEnabled",
    "sunfishCardEnabled",
  ]);
  const reducedFormEnabled = results["reducedFormEnabled"];
  const evenIntegrationEnabled = results["evenIntegration"];
  const frbPromoEnabled = results["frbPromo"] && results["evenIntegration"];
  const sunfishCardEnabled = results["sunfishCardEnabled"];
  if (
    formValues &&
    (evenIntegrationEnabled || frbPromoEnabled) &&
    completedAllRequiredSunfishFormFields(formValues, reducedFormEnabled)
  ) {
    let evenValues = {};
    if (reducedFormEnabled) {
      evenValues = {
        "app.purpose": "baby",
        "app.primaryPhone": formValues["telephone"],
        "app.firstName": formValues["firstName"],
        "app.lastName": formValues["lastName"],
        "app.email": encodeURIComponent(formValues["email"]),
        "app.address1": encodeURIComponent(formValues["address1"]),
        "app.address2": encodeURIComponent(formValues["address2"]),
        "app.city": encodeURIComponent(formValues["city"]),
        "app.state": formValues["state"],
        "app.dateOfBirth": encodeURIComponent(
          dateOfBirthFormatter(formValues["dateOfBirth"]),
        ),
        "app.loanAmount": formValues["loanAmount"],
        "tag.uId": userUid,
      };
    } else {
      evenValues = {
        "app.employmentStatus": employmentStatusFormatter(
          formValues["employmentStatus"],
        ),
        "app.educationLevel": highestLevelOfEducationFormatter(
          formValues["highestLevelOfEducation"],
        ),
        "app.purpose": "baby",
        "app.primaryPhone": formValues["telephone"],
        "app.propertyStatus": propertyStatusFormatter(formValues["ownOrRent"]),
        "app.firstName": formValues["firstName"],
        "app.lastName": formValues["lastName"],
        "app.email": encodeURIComponent(formValues["email"]),
        "app.address1": encodeURIComponent(formValues["address1"]),
        "app.address2": encodeURIComponent(formValues["address2"]),
        "app.city": encodeURIComponent(formValues["city"]),
        "app.state": formValues["state"],
        "app.employmentPayFrequency": payFrequencyFormatter(
          formValues["incomeFrequency"],
        ),
        "app.dateOfBirth": encodeURIComponent(
          dateOfBirthFormatter(formValues["dateOfBirth"]),
        ),
        "app.providedCreditRating": creditScoreFormatter(
          formValues["creditScore"],
        ),
        "app.loanAmount": formValues["loanAmount"],
        "app.annualIncome": formValues["individualIncome"],
        "tag.uId": userUid,
      };
    }
    var url = getEvenUrl();
    const urlParams = Object.entries(evenValues)
      .map((e) => e.join("="))
      .join("&");

    const eligibleZipCodes = await getFrbEligibleZipCodes();
    var frbIntegrationEnabled = false;
    if (
      frbPromoEnabled &&
      eligibleZipCodes[formValues.zipCode] &&
      formValues.loanAmount >= 30000
    ) {
      frbIntegrationEnabled = true;
    }
    const offerMetadata = await getSunfishLoanOffer(userUid);
    let apr = offerMetadata?.apr;
    let clickedAt = offerMetadata?.clickedAt;
    let sunfishCard = false;
    if (
      sunfishCardEnabled &&
      (formValues["creditScore"] === "680-719" ||
        formValues["creditScore"] === "720+")
    ) {
      sunfishCard = true;
      if (apr === undefined) {
        apr = randomIntFromInterval(10, 15);
        await postSunfishLoanOffer(userUid, apr);
      }
    }
    url = `${window.location.origin}/even?url=${encodeURIComponent(
      `${url}&${urlParams}`,
    )}&evenEnabled=${evenIntegrationEnabled}&frbEnabled=${frbIntegrationEnabled}&uId=${userUid}&sunfishCard=${sunfishCard}&apr=${apr}&email=${encodeURIComponent(
      formValues["email"],
    )}&canSubscribe=${clickedAt === undefined}&dob=${encodeURIComponent(
      dateOfBirthFormatter(formValues["dateOfBirth"]),
    )}`;
    return url;
  } else {
    return undefined;
  }
}

export function onAuthChange() {
  auth.onAuthStateChanged((newUser) => {
    if (newUser) {
      if (newUser?.uid) {
        setUserId(analytics, newUser?.uid);
      }
      getUserAcls(newUser.uid).then((acl) => {
        fetchSunfishForm(newUser.uid).then(async (sfForm) => {
          const flags = await areFeatureFlagsEnabled(["upside"]);

          // If Upside is enabled, do not automatically send user to Even
          if (!flags.upside) {
            createUsersOffersUrl(newUser?.uid, sfForm).then(
              (usersOffersUrl) => {
                Store.dispatch(setUsersOffersURL(usersOffersUrl));
              },
            );
          }

          Store.dispatch(
            setUserState({
              uid: newUser.uid,
              email: newUser.email,
              ...sfForm,
              ...acl,
            }),
          );
          Store.dispatch(setSignUpModalState({ showSignupModal: false }));
          Store.dispatch(
            setLoginModalState({
              showLoginModal: false,
              authRequired: false,
            }),
          );
        });
      });
    } else {
      Store.dispatch(setUserState(null));
      Store.dispatch(setUsersOffersURL(undefined));
    }
  });
}

export function signUp(
  email,
  password,
  location,
  affiliateId,
  affiliateUserId,
  partnerUserId,
  partner,
  linkId,
  linkUserId,
) {
  return new Promise((resolve, reject) => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            if (affiliateId !== undefined && affiliateUserId !== undefined) {
              progressAffiliatedUser({
                affiliateId: affiliateId,
                affiliateUserId: affiliateUserId,
                affiliateStatus: "registered",
              });
            }
            if (partner !== undefined && partnerUserId !== undefined) {
              progressPartnerUser({
                partnerUserId: partnerUserId,
                partner: partner,
                affiliateStatus: "registered",
              });
            }
            if (linkId !== undefined && linkUserId !== undefined) {
              progressTrackableLink({
                link: linkId,
                linkUserId: linkUserId,
                affiliateStatus: "registered",
              });
            }
            resolve(userCredential.user);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signIn(email, password) {
  return new Promise((resolve, reject) => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            resolve(userCredential.user);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sunfishSignOut() {
  return new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        resolve(true);
        Store.dispatch(setUserState(null));
      })
      .catch((error) => {
        reject(false);
      });
  });
}

export function sendPasswordReset(email, actionCodeSettings) {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          resolve();
        } else {
          reject(error);
        }
      });
  });
}

export async function getCostCalculatorQuestions(type) {
  const reqPath = `/cost-calculator/questions/${type}`;
  const dbRef = ref(realtimeDb);
  const qSnapshot = await get(child(dbRef, reqPath));
  if (qSnapshot.exists()) {
    return qSnapshot.val();
  }
  return null;
}

const listContainsFilter = (filters, filter) => {
  var currentFilters = filters;
  for (var x = 0; x < currentFilters.length; x++) {
    const element = currentFilters[x];
    if (filter.name === element.constructor.name) {
      return true;
    }
  }
  return false;
};

export async function getGrants(lastElement, filters) {
  var _grants = [];
  if (filters.length > 0) {
    _grants = await _fetchGrantsByFilters(lastElement, filters);
  } else {
    _grants = _fetchGrants(lastElement, filters);
  }
  var sortByGrantSizeFilter = listContainsFilter(
    filters,
    SortByGrantSizeFilter,
  );
  if (sortByGrantSizeFilter) {
    if (sortByGrantSizeFilter === SortByGrantSize.highToLow) {
      return _grants.sort((a, b) => a.grantSize < b.grantSize);
    } else {
      return _grants.sort((a, b) => a.grantSize > b.grantSize);
    }
  } else {
    return _grants;
  }
}

export async function _fetchGrants(lastElement) {
  const reqPath = `/grants`;
  const dbRef = ref(realtimeDb);
  const qSnapshot = await get(
    query(
      child(dbRef, reqPath),
      orderByChild("participant"),
      startAt(lastElement, "participant"),
      limitToFirst(pageSize),
    ),
  );
  if (qSnapshot.exists()) {
    const values = qSnapshot.val();
    const array = Object.values(values).sort((a, b) => {
      if (a.participant > b.participant.toString()) {
        return 1;
      } else if (b.participant > a.participant.toString()) {
        return -1;
      } else {
        return 0;
      }
    });
    return array;
  } else {
    return [];
  }
}

export async function _fetchGrantsByFilters(lastElement, filters) {
  var lastSearchedElement = lastElement;
  var filteredGrants = [];
  while (true) {
    var newGrants = await _fetchGrants(lastSearchedElement);
    if (newGrants.length === 0) {
      return filteredGrants;
    }
    lastSearchedElement = newGrants[newGrants.length - 1].participant;
    filteredGrants = [...filteredGrants, ...newGrants];
    for (var x = 0; x < filters.length; x++) {
      const grantFilter = filters[x];
      filteredGrants = filteredGrants.filter((element) =>
        grantFilter.isGrantValid(element),
      );
    }
    if (filteredGrants.length >= pageSize) {
      return filteredGrants;
    }
  }
}

export async function calculateCosts(type, request) {
  const req = {
    data: {
      ...request,
      type: type,
    },
  };

  const params = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(req),
  };

  const response = await fetch(costCalculatorUrl, params);
  return response.json();
}

export async function postOfferRequest(userId, user) {
  const reqPath = `/users/${userId}`;
  const dbRef = ref(realtimeDb, reqPath);
  await set(dbRef, user);
}

export async function updateOfferRequest(userId, user) {
  const reqPath = `/users/${userId}`;
  const dbRef = ref(realtimeDb, reqPath);
  await update(dbRef, user);
}

export async function postSunfishLoanOffer(userId, apr) {
  const reqPath = `/marketplace/${userId}/sunfish/`;
  const dbRef = ref(realtimeDb, reqPath);
  await update(dbRef, { apr: apr });
}

export async function getSunfishLoanOffer(userId) {
  const reqPath = `/marketplace/${userId}/sunfish/`;
  const dbRef = ref(realtimeDb);
  const snapshot = await get(child(dbRef, reqPath));
  if (snapshot.exists()) {
    return snapshot?.val();
  } else {
    return undefined;
  }
}

export function fetchSunfishForm(userId) {
  return new Promise((resolve, reject) => {
    const dbRef = ref(realtimeDb);
    get(child(dbRef, `users/${userId}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const sfForm = snapshot.val();
          // required for legacy feilds compatibility
          sfForm.incomeFrequency = payFrequencyLegacySupportFormatter(
            sfForm?.incomeFrequency,
          );
          resolve(sfForm);
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function notifyEmailSupportRequest(email) {
  const req = {
    data: {
      email,
    },
  };

  const params = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(req),
  };

  return new Promise((resolve, reject) => {
    fetch(requestEmailSupportUrl, params)
      .then((response) => {
        if (!response.ok) {
          response
            .json()
            .then((json) => {
              reject(json);
            })
            .catch((_) => {
              reject({
                message:
                  "Unable to register your email address.  Please contact support@joinsunfish.com.",
              });
            });
        } else {
          resolve();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function logAnalyticsEvent(event, parameters) {
  logEvent(analytics, event, parameters);
}

export function getEvenUrl() {
  return firebaseConfig["evenIntegration"];
}

export function logAnalyticsPageView(path, search, title) {
  ga4react
    .then((ga) => {
      ga.pageview(path, search, title);
    })
    .catch((err) => console.error(`Analytics failed: ${err}`));
}

export function isFeatureFlagEnabled(ffName) {
  return new Promise((resolve, reject) => {
    const dbRef = ref(realtimeDb);
    get(child(dbRef, `featureFlags`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const featureFlags = snapshot.val();
          resolve(featureFlags[ffName]);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function areFeatureFlagsEnabled(ffNames) {
  return new Promise((resolve, reject) => {
    const dbRef = ref(realtimeDb);
    get(child(dbRef, "featureFlags"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const featureFlags = snapshot.val();

          let results = {};

          ffNames.forEach((ffName) => {
            results[ffName] = featureFlags[ffName];
          });

          resolve(results);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFrbEligibleZipCodes() {
  return new Promise((resolve, reject) => {
    const dbRef = ref(realtimeDb);
    get(child(dbRef, "partners/frb/frb_regions"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          reject({
            code: "no-frb-zip-codes",
            message: "No FRB ZIP codes were available.",
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function uploadLeadsEvenLoansCsv(
  perfomanceReportCSVFiles,
  leadLevelReportCSVFiles,
) {
  return new Promise((resolve, reject) => {
    if (
      !perfomanceReportCSVFiles ||
      perfomanceReportCSVFiles.length !== 1 ||
      !leadLevelReportCSVFiles ||
      leadLevelReportCSVFiles.length !== 1
    ) {
      reject({
        code: "wrong-csv-file-count",
        message: `Expected CSV uploads`,
      });
      return;
    }

    Papa.parse(perfomanceReportCSVFiles[0], {
      error: (error) => {
        reject(error);
      },
      complete: (perfomanceReportCSVFilesResults) => {
        console.log(
          "Parsed ",
          perfomanceReportCSVFilesResults.data.length,
          " lines in the Perfomance Report CSV.",
        );

        if (perfomanceReportCSVFilesResults.data.length === 0) {
          reject({
            code: "no-header-row",
            message: "The Perfomance Report CSV does not have a header row.",
          });
          return;
        }

        let colIndexByName = new Map();
        let leadsReport = new Map();

        for (
          let colIndex = 0;
          colIndex < perfomanceReportCSVFilesResults.data[0].length;
          ++colIndex
        ) {
          const colName =
            perfomanceReportCSVFilesResults.data[0][colIndex].trim();
          if (colName) {
            colIndexByName.set(colName, colIndex);
          }
        }

        if (!colIndexByName.has("Sunfish ID")) {
          reject({
            code: "missing-sunfish-id",
            message:
              "The Perfomance Report CSV does not have a Sunfish ID column. have you selected the right CSV file?",
          });
          return;
        }
        if (!colIndexByName.has("Even ID")) {
          reject({
            code: "missing-even-id",
            message:
              "The Perfomance Report CSV does not have a Even ID column.",
          });
          return;
        }

        if (!colIndexByName.has("Leads")) {
          reject({
            code: "missing-leads",
            message: "The Perfomance Report CSV does not have a Leads column.",
          });
          return;
        }
        if (!colIndexByName.has("Submitted Applications")) {
          reject({
            code: "missing-submitted-applications",
            message:
              "The Perfomance Report CSV does not have a Submitted Applications column.",
          });
          return;
        }
        if (!colIndexByName.has("Pre-Approvals")) {
          reject({
            code: "missing-pre-approvals",
            message:
              "The Perfomance Report CSV does not have a Pre-Approvals column.",
          });
          return;
        }
        if (!colIndexByName.has("Loan Offer Clicks")) {
          reject({
            code: "missing-loan-offer-clicks",
            message:
              "The Perfomance Report CSV does not have a Loan Offer Clicks column.",
          });
          return;
        }
        if (!colIndexByName.has("Funded Loans")) {
          reject({
            code: "missing-funded-loans",
            message:
              "The Perfomance Report CSV does not have a Funded Loans column.",
          });
          return;
        }
        if (!colIndexByName.has("State")) {
          reject({
            code: "missing-state",
            message: "The Performance Report CSV does not have a State column.",
          });
          return;
        }

        let updateId = {};

        for (
          let rowIndex = 1;
          rowIndex < perfomanceReportCSVFilesResults.data.length;
          ++rowIndex
        ) {
          const evenId =
            perfomanceReportCSVFilesResults.data[rowIndex][
              colIndexByName.get("Even ID")
            ];
          const sunfishId =
            perfomanceReportCSVFilesResults.data[rowIndex][
              colIndexByName.get("Sunfish ID")
            ];

          if (!evenId || !sunfishId) {
            continue;
          }
          updateId[evenId] = sunfishId;

          if (!leadsReport.has(evenId)) {
            const performanceRecord = {
              state:
                perfomanceReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("State")
                ],
              leads: parseInt(
                perfomanceReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Leads")
                ],
              ),
              submittedApplications: parseInt(
                perfomanceReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Submitted Applications")
                ],
              ),
              preApprovals: parseInt(
                perfomanceReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Pre-Approvals")
                ],
              ),
              offerClicks: parseInt(
                perfomanceReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Loan Offer Clicks")
                ],
              ),
              fundedLoans: parseInt(
                perfomanceReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Funded Loans")
                ],
              ),
            };

            leadsReport.set(evenId, performanceRecord);
          }
        }

        Papa.parse(leadLevelReportCSVFiles[0], {
          error: (error) => {
            reject(error);
          },
          complete: (leadLevelReportCSVFilesResults) => {
            console.log(
              "Parsed ",
              leadLevelReportCSVFilesResults.data.length,
              " lines in the Lead Level Report CSV.",
            );

            if (leadLevelReportCSVFilesResults.data.length === 0) {
              reject({
                code: "no-header-row",
                message:
                  "The Lead Level Report CSV does not have a header row.",
              });
              return;
            }
            let colIndexByName = new Map();

            for (
              let colIndex = 0;
              colIndex < leadLevelReportCSVFilesResults.data[0].length;
              ++colIndex
            ) {
              const colName =
                leadLevelReportCSVFilesResults.data[0][colIndex].trim();
              if (colName) {
                colIndexByName.set(colName, colIndex);
              }
            }

            if (!colIndexByName.has("Uuid")) {
              reject({
                code: "missing-uuid",
                message:
                  "The Lead Level Report CSV does not have a Uuid column.",
              });
              return;
            }

            if (!colIndexByName.has("Property Status")) {
              reject({
                code: "missing-property-status",
                message:
                  "The Lead Level Report CSV does not have a Property Status column.",
              });
              return;
            }

            if (!colIndexByName.has("Ordered Credit Rating")) {
              reject({
                code: "missing-ordered-credit-rating",
                message:
                  "The Lead Level Report CSV does not have an Ordered Credit Ratings column.",
              });
              return;
            }

            if (!colIndexByName.has("Annual Income")) {
              reject({
                code: "missing-annual-income",
                message:
                  "The Lead Level Report CSV does not have an Annual Income column.",
              });
              return;
            }

            if (!colIndexByName.has("Employment Pay Frequency")) {
              reject({
                code: "missing-employment-pay-frequency",
                message:
                  "The Lead Level Report CSV does not have an Employment Pay Frequency column.",
              });
              return;
            }

            if (!colIndexByName.has("Employment Status")) {
              reject({
                code: "missing-employment-status",
                message:
                  "The Lead Level Report CSV does not have an Employment Status column.",
              });
              return;
            }

            if (!colIndexByName.has("Education Information Education Level")) {
              reject({
                code: "missing-education-information-education-levels",
                message:
                  "The Lead Level Report CSV does not have an Education Information Education Level column.",
              });
              return;
            }

            if (!colIndexByName.has("Attributed Date")) {
              reject({
                code: "missing-attribution-date",
                message:
                  "The Lead Level Report CSV does not have an Attributed Date column.",
              });
              return;
            }

            if (!colIndexByName.has("Demand Sub Account Name")) {
              reject({
                code: "missing-demand-sub-account-name",
                message:
                  "The Lead Level Report CSV does not have an Demand Sub Account Name column.",
              });
              return;
            }

            if (!colIndexByName.has("Requested Loan Amount")) {
              reject({
                code: "missing-requested-loan-amount",
                message:
                  "The Lead Level Report CSV does not have a Requested Loan Amount column.",
              });
              return;
            }

            let fundingUpdates = [];

            for (
              let rowIndex = 1;
              rowIndex < leadLevelReportCSVFilesResults.data.length;
              ++rowIndex
            ) {
              const Uuid =
                leadLevelReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Uuid")
                ];
              const sunfishId = updateId[Uuid];
              if (!sunfishId) {
                continue;
              }

              const evenFundingEntry = {
                sunfishId,
                ownOrRent: evenPropertyStatusFormatter(
                  leadLevelReportCSVFilesResults.data[rowIndex][
                    colIndexByName.get("Property Status")
                  ],
                ),
                creditScore: evenCreditScoreFormatter(
                  leadLevelReportCSVFilesResults.data[rowIndex][
                    colIndexByName.get("Ordered Credit Rating")
                  ],
                ),
                individualIncome:
                  leadLevelReportCSVFilesResults.data[rowIndex][
                    colIndexByName.get("Annual Income")
                  ],
                incomeFrequency: evenPayFrequencyFormatter(
                  leadLevelReportCSVFilesResults.data[rowIndex][
                    colIndexByName.get("Employment Pay Frequency")
                  ],
                ),
                employmentStatus: evenEmploymentStatusFormatter(
                  leadLevelReportCSVFilesResults.data[rowIndex][
                    colIndexByName.get("Employment Status")
                  ],
                ),
                highestLevelOfEducation: evenHighestLevelOfEducationFormatter(
                  leadLevelReportCSVFilesResults.data[rowIndex][
                    colIndexByName.get("Education Information Education Level")
                  ],
                ),
              };

              const attributionDate =
                leadLevelReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Attributed Date")
                ];

              const lender =
                leadLevelReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Demand Sub Account Name")
                ];

              const requestedLoanAmount =
                leadLevelReportCSVFilesResults.data[rowIndex][
                  colIndexByName.get("Requested Loan Amount")
                ];

              fundingUpdates.push(evenFundingEntry);

              if (!leadsReport.has(Uuid)) {
                leadsReport.set(Uuid, {
                  lenders: [lender],
                  ...evenFundingEntry,
                });
              } else {
                let report = leadsReport.get(Uuid);

                if (!report.lenders) {
                  leadsReport.set(Uuid, {
                    lenders: [lender],
                    requestedLoanAmount,
                    attributionDate,
                    ...evenFundingEntry,
                    ...report,
                  });
                } else {
                  report.lenders.push(lender);
                }
              }
            }

            const uploadEvenLeadsReport = httpsCallable(
              functions,
              "uploadEvenLeadsReport",
            );

            uploadEvenLeadsReport({
              users: fundingUpdates,
              leads: Object.fromEntries(leadsReport),
            })
              .then((res) => {
                const data = res.data;
                if (data) {
                  resolve(data);
                } else {
                  reject({
                    code: "api-error",
                    message: "API Error",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                reject({
                  code: "server-error",
                  message:
                    "An error occurred on upload.  Please reach out to Engineering to learn more.",
                });
              });
          },
        });
      },
    });
  });
}

export function uploadFundedEvenLoansCsv(files) {
  return new Promise((resolve, reject) => {
    if (!files || files.length !== 1) {
      reject({
        code: "wrong-csv-file-count",
        message: `Expected 1 CSV to upload, but found ${files?.length || 0}`,
      });
      return;
    }

    Papa.parse(files[0], {
      error: (error) => {
        reject(error);
      },
      complete: (results) => {
        console.log(
          "Parsed ",
          results.data.length,
          " lines in the funded-loans CSV.",
        );

        if (results.data.length === 0) {
          reject({
            code: "no-header-row",
            message: "The funded-loans CSV does not have a header row.",
          });
          return;
        }

        let colIndexByName = new Map();

        for (let colIndex = 0; colIndex < results.data[0].length; ++colIndex) {
          const colName = results.data[0][colIndex].trim();
          if (colName) {
            colIndexByName.set(colName, colIndex);
          }
        }

        if (!colIndexByName.has("Booked Date")) {
          reject({
            code: "missing-booked-date",
            message: "The uploaded CSV does not have a Booked Date column.",
          });
          return;
        }

        if (!colIndexByName.has("Sunfish ID")) {
          reject({
            code: "missing-sunfish-id",
            message: "The uploaded CSV does not have a Sunfish ID column.",
          });
          return;
        }

        if (!colIndexByName.has("Even ID")) {
          reject({
            code: "missing-even-id",
            message: "The uploaded CSV does not have an Even ID column.",
          });
          return;
        }

        if (!colIndexByName.has("Lender")) {
          reject({
            code: "missing-lender",
            message: "The uploaded CSV does not have a Lender column.",
          });
          return;
        }

        if (!colIndexByName.has("Funded Loans")) {
          reject({
            code: "missing-funded-loans-column",
            message: "The uploaded CSV does not have a Funded Loans column.",
          });
          return;
        }

        if (!colIndexByName.has("Total Loan Funded Amount")) {
          reject({
            code: "missing-total-loan-amount-funded",
            message:
              "The uploaded CSV does not have a Total Loan Funded Amount column.",
          });
          return;
        }

        if (!colIndexByName.has("Total Payout")) {
          reject({
            code: "missing-total-payout",
            message: "The uploaded CSV does not have a Total Payout column.",
          });
          return;
        }

        let fundingUpdates = [];

        for (let rowIndex = 1; rowIndex < results.data.length; ++rowIndex) {
          const entryId = results.data[rowIndex][0].trim();

          const sunfishId =
            results.data[rowIndex][colIndexByName.get("Sunfish ID")];

          if (!entryId || !sunfishId) {
            continue;
          }

          const evenFundingEntry = {
            sunfishId,
            bookedDate:
              results.data[rowIndex][colIndexByName.get("Booked Date")],
            evenId: results.data[rowIndex][colIndexByName.get("Even ID")],
            lender: results.data[rowIndex][colIndexByName.get("Lender")],
            fundedLoans: parseInt(
              results.data[rowIndex][colIndexByName.get("Funded Loans")],
            ),
            totalAmountFunded: currency(
              results.data[rowIndex][
                colIndexByName.get("Total Loan Funded Amount")
              ],
            ).value,
            totalPayout: currency(
              results.data[rowIndex][colIndexByName.get("Total Payout")],
            ).value,
          };

          const reqPath = `/marketplace/${sunfishId}/even/fundings/${entryId}`;
          const fundingDbRef = ref(realtimeDb, reqPath);
          fundingUpdates.push(set(fundingDbRef, evenFundingEntry));
        }

        Promise.all(fundingUpdates)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      },
    });
  });
}

export function updateMarketingConsent(userId, newConsentState) {
  return new Promise((resolve, reject) => {
    const dbRef = ref(realtimeDb, `/users/${userId}`);
    const updates = {
      agreedToMarketing: newConsentState,
    };
    update(dbRef, updates)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateSunfishForm(userId, field, value) {
  return new Promise((resolve, reject) => {
    if (!userId || !field) {
      reject({
        code: "invalid-input",
        message: "The User ID or Field are invalid",
      });
      return;
    }
    const reqPath = `/users/${userId}`;
    const dbRef = ref(realtimeDb, reqPath);
    update(dbRef, { [field]: value })
      .then(resolve)
      .catch(reject);
  });
}

export function auditAuthDatabase() {
  return new Promise((resolve, reject) => {
    const auditAuthDatabase = httpsCallable(functions, "auditAuthDatabase");
    auditAuthDatabase({}).then(resolve).catch(reject);
  });
}

export function adminBackfillSqlDb() {
  return new Promise((resolve, reject) => {
    const backfillSqlDb = httpsCallable(functions, "backfillSqlDatabase");
    backfillSqlDb({})
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
}

export function adminBackfillMarketplaceStatisticsToHubspot(files) {
  return new Promise((resolve, reject) => {
    if (!files || files.length !== 1) {
      reject({
        code: "wrong-csv-file-count",
        message: `Expected 1 CSV to upload, but found ${files?.length || 0}`,
      });
      return;
    }

    Papa.parse(files[0], {
      error: (error) => {
        reject(error);
      },
      complete: (results) => {
        console.log(
          "Parsed ",
          results.data.length,
          " lines in the funded-loans CSV.",
        );

        if (results.data.length === 0) {
          reject({
            code: "no-header-row",
            message: "The funded-loans CSV does not have a header row.",
          });
          return;
        }

        let colIndexByName = new Map();

        for (let colIndex = 0; colIndex < results.data[0].length; ++colIndex) {
          const colName = results.data[0][colIndex].trim();
          if (colName) {
            colIndexByName.set(colName, colIndex);
          }
        }

        if (!colIndexByName.has("Sunfish ID")) {
          reject({
            code: "missing-zendesk-id",
            message: "The uploaded CSV does not have a Zendesk ID column.",
          });
          return;
        }

        let sunfishIds = [];

        for (let rowIndex = 1; rowIndex < results.data.length; ++rowIndex) {
          const sunfishId =
            results.data[rowIndex][colIndexByName.get("Sunfish ID")];

          if (!sunfishId) {
            continue;
          }

          sunfishIds.push(sunfishId);
        }

        console.log("Requesting ", sunfishIds.length, " users be updated.");

        const bhced = httpsCallable(
          functions,
          "backfillHubspotContactEvenData",
        );
        bhced({ data: sunfishIds }).then(resolve).catch(reject);
      },
    });
  });
}

export async function getIpAddress() {
  const callable = httpsCallable(functions, "getIpAddress");

  const ip = await callable().then((res) => res.data.ipAddress);

  return ip;
}

export const getUpsideToken = async () => {
  const flags = await areFeatureFlagsEnabled(["upside"]);

  if (!flags.upside) {
    return new Promise((resolve, reject) =>
      reject("Upside feature is not enabled"),
    );
  }

  const callable = httpsCallable(functions, "upsideGetToken");

  return callable();
};

export const getUpsideLeadSubmission = async (payload) => {
  const flags = await areFeatureFlagsEnabled(["upside"]);

  if (!flags.upside) {
    return new Promise((resolve, reject) =>
      reject("Upside feature is not enabled"),
    );
  }

  const upsideLeadSubmission = httpsCallable(functions, "upsideLeadSubmission");

  return upsideLeadSubmission(payload);
};

export function getMarketingLeadOrganizations() {
  return new Promise((resolve, reject) => {
    const dbRef = ref(realtimeDb);
    get(child(dbRef, "marketing_leads/organizations"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
