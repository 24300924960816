import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MyProfileButton({ showMenu }) {
  const user = useSelector((state) => state.userState.user);

  const handleClick = () => {
    window.top.postMessage({ type: "route", route: "/profile" }, "*");
    if (showMenu) {
      showMenu();
    }
  };

  return user?.loanAmount !== undefined ? (
    <Link
      to="/profile"
      aria-label="profile"
      className="mx-[24px] w-[138px] h-10 px-8 py-4 bg-slate-400 rounded-[100px] justify-center items-center gap-6 inline-flex"
      onClick={handleClick}
    >
      <button className="text-center text-white text-base font-semibold font-['Jost'] capitalize">
        My Profile
      </button>
    </Link>
  ) : (
    <></>
  );
}
