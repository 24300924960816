import React from "react";
import AuthButton from "./AuthButton";
import GetOptionsButton from "./GetOptionsButton";
import { useSelector } from "react-redux";
import MyProfileButton from "./MyProfileButton";

export default function NavBarFooter({ showMenu }) {
  const loginModalState = useSelector(
    (state) => state.userState.loginModalState,
  );
  return (
    <div
      // padding required for wordpress and mobile view alignment
      className={`flex items-center lg:text-sm sm:text-xl ${
        loginModalState?.showLoginModal ? "pt-6 pr-5" : "pt-[8px]"
      }`}
    >
      <MyProfileButton showMenu={showMenu} />
      <GetOptionsButton />
      <AuthButton />
    </div>
  );
}
