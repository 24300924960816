import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLoginModalState } from "../../App/Features/UserSplice";
import { sunfishSignOut } from "../../api";

export default function AuthButton() {
  const dispatch = useDispatch();

  const user = useSelector(({ userState }) => userState.user);

  const logOut = () => {
    sunfishSignOut();
  };

  const handleLogin = () => {
    // This was implemented only due to iframes not allowing the use of react hooks
    // Such as useState and useEffect. To get around the issue, postMessage to Wordpress immediately
    // (Wordpress should hide the frame then reset it's styles to be fullscreen. There is a setTimeout there to display it again)
    // Delay the dispatch to prevent the blinking
    window.top.postMessage("enter.fullscreen_mode", "*");

    setTimeout(() => {
      dispatch(
        setLoginModalState({
          showLoginModal: true,
          authRequired: false,
        }),
      );
    }, 100);
  };

  return (
    <div className="items-center lg:text-sm sm:text-xl">
      {user === null ? (
        <button
          onClick={handleLogin}
          className="px-[24px] text-center lg:text-[#003269] md:text-white text-base font-medium font-['Jost'] capitalize"
        >
          login
        </button>
      ) : (
        <button
          className="w-[105px] px-[24px] text-center lg:text-[#003269] md:text-white text-base font-medium font-['Jost'] capitalize"
          onClick={logOut}
        >
          Log out
        </button>
      )}
    </div>
  );
}
