import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import EducationalResources from "./Pages/EducationalResources";
import LgbtqParenthood from "./Pages/LgbtqParenthood";
import FinancingOptions from "./Pages/FinancingOptions";
import OurMission from "./Pages/OurMission";
import Loginform from "./Components/Loginform";
import SignupForm from "./Components/SignupForm";
import ResetPasswordForm from "./Components/ResetPasswordForm";
import CostCalculator from "./Pages/CostCalculator";
import LoanEstimator from "./Pages/LoanEstimator";
import Nav from "./Components/Nav/Nav";
import AddBlogsAndEvents from "./Pages/AddBlogsAndEvents";
import BlogsAndEvents from "./Pages/BlogsAndEvents";
import Grants from "./Pages/Grants";
import OurOfferings from "./Pages/OurOfferings";
import Disclosures from "./Pages/Disclosures";
import ECommConsent from "./Pages/ECommunicationsConsent";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import WarrantyQualifications from "./Pages/WarrantyQualifications";
import EvenFundingUploadPage from "./Pages/EvenFundingUpload";
import EvenLeadsUploadPage from "./Pages/EvenLeadsUpload";
import AdminPage from "./Pages/Admin";
import {
  onAuthChange,
  logAnalyticsEvent,
  logAnalyticsPageView,
  areFeatureFlagsEnabled,
  getAffiliates,
  postAffiliatedUser,
  updateAffiliatedUserAffiliation,
  getTrackableLinks,
  postTrackableLinkUser,
} from "./api";
import { useSelector } from "react-redux";
import {
  setCurrentPathState,
  setPreviousPathState,
} from "./App/Features/NavSplice";
import Store from "./App/Store";
import ECommunicationModal from "./Components/ECommunicationModal";
import PartnersManagement from "./Pages/PartnersManagement";
import Footer from "./Components/Footer";
import ScrollToTop from "./ScrollToTop";
import Profile from "./Pages/Profile";
import PartnerProviders from "./Pages/PartnerProviders";
import PartnerProvidersContactUs from "./Pages/PartnerProvidersContactUs";
import {
  setScrollIntoFormEnabled,
  setReducedFormEnabled,
} from "./App/Features/AppSlice";
import AffiliateManagement from "./Pages/AffiliateManagement";
import TrackableLinkManagement from "./Pages/TrackableLinkManagement";
import {
  hasAffiliateId,
  setAffiliate,
  setAffiliateUserId,
  hasAffiliateUserId,
  resetCachedAffiliate,
  resetCachedAffiliateUserId,
} from "./App/Features/AffiliateSlice";
import { setAffiliates } from "./App/Features/AffiliateManagementSlice";
import Warranty from "./Pages/Warranty";
import NavBarFooter from "./Components/Nav/NavBarFooter";
import AuthButton from "./Components/Nav/AuthButton";
import GetOptionsButton from "./Components/Nav/GetOptionsButton";
import SunfishForm from "./Components/OurOfferings/SunfishForm";
import FreezingComponent from "./Components/CostCalculator/FreezingComponent";
import IvfComponent from "./Components/CostCalculator/IvfComponent";
import SurrogacyComponent from "./Components/CostCalculator/SurrogacyComponent";
import IframeResizer from "iframe-resizer-react";
import MyProfileButton from "./Components/Nav/MyProfileButton";
import {
  hasLinkId,
  setLink,
  setUserId,
  hasUserId,
} from "./App/Features/TrackableLinkSlice";
import { setLinks } from "./App/Features/TrackableLinkManagementSlice";
import PartnerMessage from "./Components/PartnerMessage";
import GetStarted from "./Components/Warranty/GetStarted";
import PrivacyPolicyModal from "./Components/PrivacyPolicyModal";
import LoadingComponent from "Components/LoadingComponent";

export default function App() {
  const currentPath = useSelector((state) => state.navState.currentPath);
  const previousPath = useSelector((state) => state.navState.previousPath);
  const setCurrentPath = (value) => Store.dispatch(setCurrentPathState(value));
  const setPreviousPath = (value) =>
    Store.dispatch(setPreviousPathState(value));

  const showECommunicationModal = useSelector(
    (state) => state.navState.eCommunicationModal.showECommunicationModal,
  );
  const showPrivacyPolicyModal = useSelector(
    (state) => state.navState.privacyPolicyModal.showPrivacyPolicyModal,
  );
  const location = useLocation();
  const affiliates = useSelector(
    (state) => state.manageAffiliateState.affiliates,
  );
  const affiliateId = useSelector((state) => state.affiliateState.affiliateId);
  const affiliateUserId = useSelector(
    (state) => state.affiliateState.affiliateUserId,
  );
  const links = useSelector(
    (state) => state.trackableLinkManagementState.links,
  );
  const showLoginModal = useSelector(
    (state) => state.userState.loginModalState?.showLoginModal,
  );
  const showSignupModal = useSelector(
    (state) => state.userState.signUpModalState?.showSignupModal,
  );
  const showResetPasswordModal = useSelector(
    (state) => state.userState.resetPasswordModalState?.showResetPasswordModal,
  );
  const upsideState = useSelector(({ upsideState }) => upsideState);

  useEffect(() => {
    if (!links) {
      getTrackableLinks().then((value) => {
        Store.dispatch(setLinks(value));
      });
    }
  }, [links]);

  useEffect(() => {
    if (links && location?.search) {
      const searchParams = new URLSearchParams(location?.search);
      if (searchParams?.has("l")) {
        const linkQuery = searchParams?.get("l");
        const link = Object.values(links).find(
          (e) => e.urlQuery === `?l=${encodeURIComponent(linkQuery)}`,
        );
        if (link) {
          const newLinkId = link.id;
          if (!hasLinkId()) {
            Store.dispatch(setLink(newLinkId));
            postTrackableLinkUser({ linkId: newLinkId }).then((key) => {
              if (key) {
                Store.dispatch(setUserId(key));
              }
            });
          }
        }
      }
    }
  }, [links, location.search]);

  useEffect(() => {
    if (!affiliates) {
      getAffiliates().then((value) => {
        Store.dispatch(setAffiliates(value));
      });
    }
  }, [affiliates]);

  useEffect(() => {
    if (affiliates && location?.search) {
      const searchParams = new URLSearchParams(location?.search);
      if (searchParams?.has("a")) {
        const affilateQuery = searchParams?.get("a");
        const affiliate = Object.values(affiliates).find(
          (e) => e.urlQuery === `?a=${affilateQuery}`,
        );
        if (affiliate) {
          const newAffilateId = affiliate.id;
          if (!hasAffiliateId()) {
            Store.dispatch(setAffiliate(newAffilateId));
            postAffiliatedUser(newAffilateId).then((key) => {
              if (key) {
                Store.dispatch(setAffiliateUserId(key));
              }
            });
          }
        }
      }
    }
  }, [affiliates, location.search]);

  useEffect(() => {
    if (affiliates && location?.search) {
      const searchParams = new URLSearchParams(location?.search);
      if (searchParams?.has("a")) {
        const affilateQuery = searchParams?.get("a");
        const affiliate = Object.values(affiliates).find(
          (e) => e.urlQuery === `?a=${affilateQuery}`,
        );
        if (affiliate) {
          const newAffilateId = affiliate.id;
          if (affiliateId !== undefined && newAffilateId !== affiliateId) {
            Store.dispatch(resetCachedAffiliate(newAffilateId));
            updateAffiliatedUserAffiliation({
              existingAffiliateId: affiliateId,
              newAffilateId: newAffilateId,
              affiliateUserId: affiliateUserId,
            }).then((key) => {
              if (key) {
                Store.dispatch(resetCachedAffiliateUserId(key));
              } else {
                Store.dispatch(resetCachedAffiliate(affiliateId));
              }
            });
          }
        }
      }
    }
  }, [affiliates, affiliateId, affiliateUserId, location.search]);

  useEffect(() => {
    if (hasAffiliateId()) {
      Store.dispatch(setAffiliate());
    }
    if (hasAffiliateUserId()) {
      Store.dispatch(setAffiliateUserId());
    }
    if (hasLinkId()) {
      Store.dispatch(setLink());
    }
    if (hasUserId()) {
      Store.dispatch(setUserId());
    }
  }, []);

  useEffect(() => {
    areFeatureFlagsEnabled([
      "scrollIntoFormEnabled",
      "reducedFormEnabled",
    ]).then((results) => {
      Store.dispatch(setReducedFormEnabled(results["reducedFormEnabled"]));
      Store.dispatch(
        setScrollIntoFormEnabled(results["scrollIntoFormEnabled"]),
      );
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
      logAnalyticsEvent("screen_view", {
        firebase_screen: location.pathname,
        firebase_screen_class: "webpage",
      });

      logAnalyticsPageView(
        location.pathname,
        location.search,
        location.pathname.split("/")[1],
      );
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    onAuthChange();
  }, []);

  const hideCommonComponents =
    location.pathname.startsWith("/auth") ||
    location.pathname.startsWith("/components");

  const anyModalsDisplayed =
    showLoginModal || showSignupModal || showResetPasswordModal;

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Sunfish: Financial Solutions for all journeys to parenthood"
        />
      </Helmet>
      {hideCommonComponents ? <> </> : <Nav />}
      {showECommunicationModal ? <ECommunicationModal /> : <></>}
      {showPrivacyPolicyModal ? <PrivacyPolicyModal /> : <></>}
      <Loginform pathOnClose={previousPath} />
      <SignupForm pathOnClose={previousPath} />
      <ResetPasswordForm pathOnClose={previousPath} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} caseSensitive={true} />
        <Route
          path="/loan-repayment-calculator"
          element={<LoanEstimator />}
          caseSensitive={true}
        />
        <Route
          path="/components/loan-repayment-calculator"
          element={<LoanEstimator />}
          caseSensitive={true}
        />
        <Route
          path="/partners"
          element={<PartnerProviders />}
          caseSensitive={true}
        />
        <Route
          path="/partners-contact-us"
          element={<PartnerProvidersContactUs />}
          caseSensitive={true}
        />
        <Route path="/profile" element={<Profile />} caseSensitive={true} />
        <Route
          path="/even/funding-upload"
          element={<EvenFundingUploadPage />}
          caseSensitive={true}
        />
        <Route
          path="/even/leads-upload"
          element={<EvenLeadsUploadPage />}
          caseSensitive={true}
        />
        <Route
          path="/educational-resources"
          element={<EducationalResources />}
          caseSensitive={true}
        />
        <Route
          path="/lgbtqParenthood"
          element={<LgbtqParenthood />}
          caseSensitive={true}
        />
        <Route
          path="/financing-options"
          element={<FinancingOptions />}
          caseSensitive={true}
        />
        <Route path="/mission" element={<OurMission />} caseSensitive={true} />
        <Route
          path="/blogs-events"
          element={<BlogsAndEvents setPreviousPath={setPreviousPath} />}
          caseSensitive={true}
        />
        <Route
          path="/blogs-events/manage"
          element={<AddBlogsAndEvents />}
          caseSensitive={true}
        />
        <Route path="/grants" element={<Grants />} caseSensitive={true} />
        <Route
          path="/cost-calculator"
          element={<CostCalculator />}
          caseSensitive={true}
        />
        <Route
          path="/our-offerings"
          element={<OurOfferings />}
          caseSensitive={true}
        />
        <Route
          path="/our-offerings/form"
          element={<OurOfferings scrollIntoForm={true} />}
          caseSensitive={true}
        />
        <Route
          path="/disclosures"
          element={<Disclosures />}
          caseSensitive={true}
        />
        <Route
          path="/ecommunications"
          element={<ECommConsent />}
          caseSensitive={true}
        />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy />}
          caseSensitive={true}
        />
        <Route
          path="/auth/nav-buttons"
          element={
            anyModalsDisplayed ? (
              <>
                <IframeResizer />
              </>
            ) : (
              <>
                <NavBarFooter />
                <IframeResizer />
              </>
            )
          }
          caseSensitive={true}
        />
        <Route
          path="/auth/my-profile-button"
          element={
            <>
              <IframeResizer />
              <MyProfileButton />
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/components/profile"
          element={<Profile />}
          caseSensitive={true}
        />
        <Route
          path="/auth/auth-button"
          element={
            <>
              <IframeResizer />
              <div className="bg-primary">
                <AuthButton />
              </div>
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/auth/get-options-button"
          element={
            <>
              <IframeResizer />
              <div className="bg-primary">
                <GetOptionsButton />
              </div>
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/logout"
          element={<Home logOut={true} />}
          caseSensitive={true}
        />
        <Route
          path="/partners/manage"
          element={<PartnersManagement />}
          caseSensitive={true}
        />
        <Route path="/:routeName" element={<Home />} caseSensitive={true} />
        <Route path="/newsletter" element={<Home />} caseSensitive={true} />
        <Route
          path="/affiliate/manage"
          element={<AffiliateManagement />}
          caseSensitive={true}
        />
        <Route
          path="/trackable_link/manage"
          element={<TrackableLinkManagement />}
          caseSensitive={true}
        />
        <Route path="/warranty" element={<Warranty />} caseSensitive={true} />
        <Route
          path="/warranty/qualifications"
          element={<WarrantyQualifications />}
          caseSensitive={true}
        />
        <Route path="/admin" element={<AdminPage />} caseSensitive={true} />
        <Route
          path="/components/warranty"
          element={<Warranty />}
          caseSensitive={true}
        />
        <Route
          path="/components/partner_message/:routeName"
          element={<PartnerMessage />}
          caseSensitive={true}
        />
        <Route
          path="/components/sf_form"
          element={
            <>
              <SunfishForm />
              <IframeResizer />
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/components/cost_calculator/eggs"
          element={
            <>
              <FreezingComponent />
              <IframeResizer />
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/components/cost_calculator/ivf"
          element={
            <>
              <IvfComponent />
              <IframeResizer />
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/components/cost_calculator/surrogacy"
          element={
            <>
              <SurrogacyComponent />
              <IframeResizer />
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/components/grants"
          element={
            <>
              <Grants />
              <IframeResizer />
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/components/partners-registration"
          element={
            <>
              <PartnerProvidersContactUs />
              <IframeResizer />
            </>
          }
          caseSensitive={true}
        />
        <Route
          path="/components/warranty-page-get-started"
          element={
            <>
              <GetStarted />
            </>
          }
          caseSensitive={true}
        />
        <Route path="*" element={<Navigate to="/" />} caseSensitive={true} />
      </Routes>
      {hideCommonComponents ? <> </> : <Footer />}

      {upsideState.loading ? <LoadingComponent /> : null}
    </>
  );
}
